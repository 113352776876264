<template>
	<div :class="mainClass">
		<b-col>
			<div :class="mainEditorClass">
				<div v-if="editor" :class="secondElEditorClass">
					<div :class="`d-flex flex-row ${thirdElEditorClass}`">
						<div>
							<a @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
								<i class="fa fa-bold" aria-hidden="true"></i>
							</a>
						</div>
						<div>
							<a @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
								<i class="fa fa-italic" aria-hidden="true"></i>
							</a>
						</div>
						<div>
							<a @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
								<i class="fa fa-strikethrough" aria-hidden="true"></i>
							</a>
						</div>
						<div>
							<a @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }">
								<i class="fa fa-underline" aria-hidden="true"></i>
							</a>
						</div>
						<div>
							<a @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">
								<i class="fa fa-quote-right" aria-hidden="true"></i>
							</a>
						</div>
						<div>
							<a @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
								<i class="fa fa-list-ol" aria-hidden="true"></i>
							</a>
						</div>
						<div>
							<input type="file" ref="iFile" :accept="acceptFiles" style="display: none" @change="addImage" />
							<a @click="$refs.iFile.click()">
								<i class="fa fa-picture-o" aria-hidden="true"></i>
							</a>
						</div>
					</div>
				</div>
				<editor-content :editor="editor" />
			</div>
		</b-col>
		<b-col sm="4" class="pull-right pt-2">
			<b-form-group class="my-0">
				<b-button type="button" variant="outline-primary" :class="`py-2 px-4 ${btnMainClass}`" block @click.stop="save">
					<i class="fa fa-paper-plane" aria-hidden="true"></i>
					<span class="ml-1">{{ FormMSG(1, 'Comment') }}</span>
				</b-button>
			</b-form-group>
		</b-col>
	</div>
</template>
<script>
import { store } from '@/store';
import languageMessages from '@/mixins/languageMessages';
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Image from '@tiptap/extension-image';
import Mention from '@tiptap/extension-mention';
import Dropcursor from '@tiptap/extension-dropcursor';
import { addComment } from '@/cruds/itemcomment.crud';
import suggestion from '@/components/Task/Mention/user-to-mentioned';

const axios = require('axios').create();

export default {
	name: 'TaskEditor',
	mixins: [languageMessages],
	props: {
		mainClass: {
			type: String,
			required: false,
			default: 'task-main-comment'
		},
		mainEditorClass: {
			type: String,
			required: false,
			default: 'task-comment-editor px-3 py-3'
		},
		secondElEditorClass: {
			type: String,
			required: false,
			default: 'pb-3'
		},
		thirdElEditorClass: {
			type: String,
			required: false,
			default: 'pb-2'
		},
		btnMainClass: {
			type: String,
			required: false,
			default: 'btn-main-send'
		},
		taskXid: {
			type: String,
			required: true,
			default: null
		},
		replyTo: {
			type: [Number, String],
			required: true,
			default: 0
		},
		isRoot: {
			type: Boolean,
			required: false,
			default: false
		},
		imageParentId: {
			type: [Number, String],
			required: true,
			default: 0
		},
		imageParentType: {
			type: String,
			required: false,
			default: 'comment'
		},
		imageParentSubType: {
			type: String,
			required: false,
			default: 'images'
		},
		apiUploadUrl: {
			type: String,
			required: false,
			default: 'upload'
		},
		acceptFiles: {
			type: String,
			required: false,
			default: '.jpg,.png,.gif,.jpeg'
		},
		formFilename: {
			type: String,
			required: false,
			default: 'uploadimage'
		}
	},
	components: {
		EditorContent
	},
	data() {
		return {
			editor: null,
			xid: null
			// rootId: 0,
		};
	},
	mounted() {
		this.editor = new Editor({
			extensions: [
				StarterKit,
				Underline,
				Image,
				Mention.configure({
					HTMLAttributes: {
						class: 'mention'
					},
					suggestion
				}),
				Dropcursor
			],
			content: ``
		});
		// console.log('isRoot: ', this.isRoot)
	},
	beforeUnmount() {
		this.editor.destroy();
	},
	methods: {
		async save() {
			if (this.editor.getText().trim() !== '' || new RegExp('(<img)+', 'g').test(this.editor.getHTML())) {
				await addComment(this.xid, this.editor.getHTML(), this.replyTo).then((node) => {
					// console.log({xid: this.xid, message: this.editor.getHTML(), replyTo: this.replyTo, node});
					let rootComment = node;
					this.$set(rootComment, 'children', []);
					rootComment.children.push({
						id: parseInt(rootComment.id, 10),
						xid: rootComment.xid,
						imageParentId: this.imageParentId,
						imageParentType: this.imageParentType,
						imageParentSubType: this.imageParentSubType
					});
					this.$emit('task-comment:added', rootComment);

					this.$bus.$emit('pushNode', {
						parentId: this.replyTo,
						isRoot: this.isRoot,
						imageParentId: this.imageParentId,
						imageParentType: this.imageParentType,
						imageParentSubType: this.imageParentSubType,
						node
					});
					this.editor.commands.setContent('');
					if (this.isRoot) {
						this.$parent.replyToggle = false;
					}
				});
			}
		},
		addImage(e) {
			const file = e.target.files[0];
			let formData = new FormData();
			formData.append(this.formFilename, file);
			formData.append('parentType', this.imageParentType);
			formData.append('parentSubType', this.imageParentSubType);
			formData.append('parentId', this.imageParentId);
			formData.append('fileName', file.name);
			formData.append('fileType', file.type);
			const urlStr = process.env.VUE_APP_GQL + '/' + this.apiUploadUrl;

			axios
				.post(urlStr, formData, {
					headers: {
						Authorization: `Bearer ${store.state.token}`,
						'Content-Type': 'multipart/form-data'
					}
				})
				.then((record) => {
					// console.log({record});
					const filename = record.data;
					const xid = filename.substring(0, filename.lastIndexOf('.'));
					const urlImage = process.env.VUE_APP_GQL + '/images/' + xid;
					this.editor.chain().focus().setImage({ src: urlImage }).run();

					this.$emit('comment-image:added', {
						parentId: this.imageParentId,
						xid
					});
				})
				.catch((e) => {
					console.error({ e });
				});
		}
	},
	watch: {
		// imageParentId: {
		//     handler: function(n) {
		//         if (!isNil(n)) {
		//             this.rootId = parseInt(n, 10);
		//         }
		//     },
		//     deep: true,
		//     immediate: true
		// },
		taskXid: {
			handler: function (n) {
				if (n !== null && n.length > 0) {
					this.xid = n;
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
<style lang="scss" scoped></style>
